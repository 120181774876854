import * as React from 'react'
import Layout from '../../components/layout'
import { Titulo } from '../../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'

export default function centroalumnos () {
    return (
        <Layout>
            <Titulo title="Centro de alumnos" />
            <Container>
                <Row className=" justify-content-center">
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/presidente.jpg" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                /> 
                                <h4>Gaspar Gonzalez</h4>
                                <h6>Presidente</h6>
                                <h6>7° básico</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/vicepresidente.jpg" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                /> 
                                <h4>Máximo Díaz</h4>
                                <h6>Vicepresidente</h6>
                                <h6>6° básico</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/secretario_ejecutivo.jpg" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                />
                                <h4>Felipe Hidalgo</h4>
                                <h6>Secretario ejecutivo</h6>
                                <h6>6° básico</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/secretaria_actas.jpg" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                /> 
                                <h4>Belén Cortés</h4>
                                <h6>Secretaria actas</h6>
                                <h6>6° básico</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/coordinador_primer_ciclo.jpg" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                /> 
                                <h4>Nallareth Chávez</h4>
                                <h6>Coordinadora primer ciclo</h6>
                                <h6>4° básico</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/secretaria_finanzas.jpg" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                /> 
                                <h4>Constanza Ramos</h4>
                                <h6>Secretaria de finanzas</h6>
                                <h6>6° básico</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12} className="mb-5"></Col>
                </Row>
                
            </Container>
        </Layout>
    )
}